.navbar {
  background-color: var(--whiteColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  box-shadow: 0 5px 15px 0px #f7951d57;
  width: 100%;
  height: 70px;
  top: 1rem;
  left: 50%;
  transform: translate(-50%);
  position: fixed;
  z-index: 9999;
}

.navbar-logo {
  cursor: pointer;
  width: 11rem;
  height: 3rem;
}

.navbar-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 2rem;
  list-style: none;
  align-items: center;
}

.navbar-links {
  text-decoration: none;
  color: var(--brownColor);
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.1rem 0.8rem;
  white-space: nowrap;
}

.navbar-links i {
  padding-right: 0.8rem;
  font-size: 1rem;
}

.navbar-links:hover {
  color: rgb(12, 243, 147);
  transition: all 0.2s ease-in-out;
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
}

.menu-icons {
  color: var(--brownColor);
  cursor: pointer;
  font-size: 1.2rem;
  display: none;
}

@media screen and (max-width: 900px) {
  .navbar {
    z-index: 99;
  }
  .navbar-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--whiteColor);
    width: 50%;
    height: auto;
    position: absolute;
    top: 0;
    right: -100%;
    opacity: 0;
    align-items: center;
    padding: 4rem 0 2rem 0;
    margin-left: 50%;
    transition: .5s ease-in-out;
    border-radius: 13px;
  }

  .navbar-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.7s ease-in-out;
  }

  .navbar-links {
    display: block;
    width: 100%;
    font-size: 0.9rem;
    padding: 1.2rem 0;
  }

  .navbar-links:hover {
    transition: 0.3s ease-in-out;
  }

  .menu-icons {
    display: block;
  }
}

