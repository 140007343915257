.serviceContainer {
  width: 100%;
  height: 240vh;
  background-color: var(--secondary);
}

.headerImg {
  position: relative;
  width: 100%;
  height: 55vh;
}

.headerImg img {
  width: 100%;
  height: 100%;
}

.major {
  display: flex;
  flex-direction: row;
  padding-top: 4lvh;
}

.plan1 {
  width: 48vw;
  height: 150vh;
}

.masterPlan {
  width: 100%;
  height: 44%;
}

.header_plan {
  background-color: var(--primary);
  color: var(--whiteColor);
  width: 40%;
  height: 7%;
  padding: 1% 0 0 7%;
  border-radius: 0 5vh 5vh 0;
  margin-left: 0;
  font-size: calc(0.3rem + 0.5vw);
}

.masterLayout {
  display: flex;
  flex-direction: row;
  padding: 2vh 2vw;
  width: 100%;
  height: 100%;
}

.building1 {
  width: 80%;
  height: 95%;
}

.building1 img {
  width: 70%;
  height: 100%;
}

.layout {
  padding-left: 2vw;
  width: 45%;
  height: 100%;
  color: var(--whiteColor);
  text-align: start;
}

.layout h3 {
  font-size: calc(0.5em + 0.8vw);
  margin-bottom: 10vh;
}

.layout h4 {
  font-size: calc(0.5em + 1vh);
  margin-bottom: 1vh;
  text-decoration: underline 0.2vw;
  text-transform: capitalize;
}

.layout ul {
  font-size: calc(0.4em + 1vh);
  line-height: 4vh;
  list-style-type: decimal;
  padding: 0.5vh 1.1vw;
}

.block {
  width: 100%;
  height: 42%;
  display: flex;
  flex-direction: row;
  padding: 0 3vw;
}

.blockBody {
  width: 91%;
  height: 100%;
}

.blockHeader {
  background-color: var(--primary);
  color: var(--whiteColor);
  font-size: calc(0.5rem + 0.3vw);
  width: 100%;
  height: 8%;
  padding-top: 1.4vh;
  margin: 0.3vh 0;
}

.blockPara {
  width: 100%;
}

.blockPara p {
  color: var(--whiteColor);
  text-align: justify;
  font-size: calc(0.5rem + 0.4vw);
  line-height: 2.5vh;
  padding: 1vh 0;
  width: 100%;
}

.blockPara img {
  width: 100%;
  padding: 2vh 0;
}

.plan2 {
  width: 48vw;
  height: 150vh;
}

.masterPlan2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 44%;
}

.building2 {
  width: 100%;
  height: 75%;
}

.building2 img {
  width: 90%;
  height: 100%;
}

.para {
  width: 100%;
  height: 20%;
}

.para h4 {
  padding-bottom: 1vh;
  font-size: calc(0.3rem + 1vw);
  color: var(--primary);
  text-align: start;
}

.para p {
  padding-top: 0.3vh;
  width: 100%;
  color: var(--whiteColor);
  text-align: justify;
  font-size: calc(0.4rem + 0.6vw);
}

.block2 {
  width: 100%;
  height: 18%;
}

.block2para {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.block2Text {
  width: 80%;
  padding: 2vh 1vw;
}

.block2Text h4 {
  font-size: calc(0.5rem + 0.6vw);
  color: var(--primary);
}

.block2Text p {
  text-align: justify;
  font-size: calc(0.4rem + 0.5vw);
  line-height: 2.5vh;
  color: var(--whiteColor);
  padding-top: 1vh;
}

.block2Img {
  width: 50%;
  height: 100%;
  padding: 2vh 1vw;
}

.block2Img img {
  width: 100%;
}

@media (max-width: 1163px) {
  .layout h3 {
    font-size: calc(0.5rem + 0.5vw);
  }

  .layout h4 {
    font-size: calc(0.4rem + 0.5vw);
  }

  .layout ul {
    font-size: calc(0.35rem + 0.5vw);
  }
}

@media (max-width: 1130px) {
  .blockHeader {
    font-size: calc(0.2rem + 0.5vw);
  }
}

@media (max-width: 790px) {
  .header_plan {
    font-size: calc(0.3rem + 0.5vw);
    padding: 1.4vh 0;
  }

  .layout h3 {
    font-size: calc(0.4rem + 0.5vw);
  }

  .layout h4 {
    font-size: calc(0.35rem + 0.5vw);
  }

  .layout ul {
    font-size: calc(0.3rem + 0.5vw);
  }
}

@media (max-width: 740px) {
  .blockHeader {
    height: 5%;
  }

  .block2Text p {
    font-size: calc(0.3rem + 0.5vw);
  }
}

@media (max-width: 695px) {
  .layout h3 {
    font-size: calc(0.3rem + 0.5vw);
  }

  .layout h4 {
    font-size: calc(0.25rem + 0.5vw);
  }

  .layout ul {
    font-size: calc(0.2rem + 0.5vw);
  }

  .block2Text h4 {
    font-size: calc(0.32rem + 0.5vw);
  }
}

@media (max-width: 550px) {
  .blockHeader {
    height: 5%;
    padding-top: 1vh;
    font-size: calc(0.15rem + 0.5vw);
  }

  .header_plan {
    font-size: calc(0.15rem + 0.5vw);
    padding: 1.1vh 0;
    height: 5%;
  }

  .block2Text p {
    font-size: calc(0.25rem + 0.5vw);
  }
}

@media (max-width: 495px) {
  .layout h3 {
    font-size: calc(0.2rem + 0.5vw);
  }

  .layout h4 {
    font-size: calc(0.1rem + 0.5vw);
  }

  .blockPara p {
    font-size: calc(0.37rem + 0.5vw);
  }
}

@media (max-width: 460px) {
  .block2Text p {
    font-size: calc(0.2rem + 0.5vw);
  }
}

@media (max-width: 382px) {
  .blockHeader {
    height: 4%;
    padding-top: 1vh;
    font-size: calc(0.1rem + 0.5vw);
  }

  .blockPara p {
    font-size: calc(0.35rem + 0.5vw);
  }

  .block2Text h4 {
    font-size: calc(0.25rem + 0.5vw);
  }

  .block2Text p {
    font-size: calc(0.15rem + 0.5vw);
  }
}
