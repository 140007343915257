.container {
  width: 100%;
  height: 611px;
  /* transform: translateY(-35%); */
  background-image: url("../assets/images/NewImgs/JGPatternBackgroundGreen.jpg");
  background-size: 80%;
}

.heroImg {
  width: 100%;
  height: 60%;
}

.heroImg img {
  position: relative;
  top: 20%;
  width: 40%;
  min-width: 100px;
  height: 90%;
}

/* .backBox {
  height: 35%;
  background-color: var(--secondary);
  transform: translateY(88vh);
} */

.homeText {
  font-size: var(--pageFontSize);
  position: relative;
  padding: 0 5%;
  top: 7%;
  width: 100%;
  height: 20%;
}

.homeText p {
  text-align: justify;
  color: var(--whiteColor);
  font-size: calc(0.1rem + 1vw);
  line-height: calc(0.5rem + 1vw);
}

@media (min-width: 1386px) {
  .container {
    height: 857px;
  }
}

@media (max-width: 990px) {
  .homeText p {
    line-height: calc(0.7rem + 1vw);
    font-size: calc(0.3rem + 1vw);
  }
}

@media (max-width: 885px) {
  .homeText p {
    line-height: calc(0.6rem + 1vw);
  }
}

@media (max-width: 770px) {
  .homeText p {
    line-height: calc(0.6rem + 1vw);
    font-size: calc(0.25rem + 1vw);
  }
}

@media (max-width: 640px) {
  .container {
    height: 623px;
  }

  .homeText p {
    line-height: calc(0.6rem + 1vw);
  }
}

@media (max-width: 520px) {
  .container {
    height: 638px;
  }
}
