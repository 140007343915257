.Abcontainer {
  width: 100%;
  height: 1350px;
  background-image: url("../assets/images/NewImgs/JGPatternBackgroundGreen.jpg");
  background-size: 80%;
}

.aboutImg {
  position: relative;
  width: 100%;
  height: 33%;
}

.aboutImg img {
  width: 100%;
  height: 100%;
}

.human {
  padding: 2% 4%;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65%;
}

.humanBox {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
}

.photo {
  width: 100%;
  padding-right: 7%;
}

.photo img {
  width: 30%;
  height: 90%;
  border-radius: 50%;
  border: 0.2rem solid var(--primary);
}

.header {
  font-size: calc((0.8em + 0.2vh) - 0.1vw);
  margin: 0 13.5%;
  width: 20lvw;
  background-color: var(--secondary);
  color: var(--primary);
}

.textBox {
  margin: 2% 13.5%;
  padding: 2% 3%;
  width: 20lvw;
  height: 80dvh;
  background-color: var(--secondary);
}

.text1 {
  font-size: calc((0.8em + 0.2vh) - 0.1vw);
  text-align: justify;
  color: var(--primary);
  margin-bottom: 12%;
  width: 18lvw;
}

.text1-1 {
  font-size: calc((0.8em + 0.2vh) - 0.1vw);
  text-align: justify;
  color: var(--primary);
  margin-bottom: 40%;
  width: 18lvw;
}

.text1-3 {
  font-size: calc((0.8em + 0.2vh) - 0.1vw);
  text-align: justify;
  color: var(--primary);
  margin-bottom: 67%;
  width: 18lvw;
}

.separater {
  color: var(--primary);
  padding: 1rem 0;
}

.text2 {
  font-size: calc((0.73em + 0.2vh) - 0.1vw);
  color: var(--whiteColor);
  text-align: justify;
}

@media (max-width: 990px) {
  .textBox {
    height: 100%;
  }
}

@media (max-width: 870px) {
  .human {
    flex-direction: column;
    width: 100%;
  }

  .humanBox {
    flex-direction: row;
    width: 100%;
    height: 26lvh;
    padding-top: 1lvh;
  }

  .photo {
    width: 25lvw;
    transform: translateX(7lvw);
    padding-top: 1lvh;
  }

  .photo img {
    width: 8lvw;
    height: 8lvh;
  }

  .header {
    font-size: calc((0.5em + 0.1lvh) - 0.1lvw);
    transform: translateX(-13lvw);
    margin: 10lvh 0;
    width: 20lvw;
    height: 4lvh;
    padding-top: 1lvh;
  }

  .textBox {
    margin: 1lvh 0;
    width: 80lvw;
    height: 100%;
    box-shadow: 0 0 0.2em 0.2em rgb(187, 135, 50);
  }

  .human > .humanBox:nth-child(1) {
    padding: 0 0;
  }

  .human > .humanBox:nth-child(3) > .textBox {
    height: 20lvh;
  }

  .separater {
    padding: 0 0;
  }

  .text1 {
    font-size: calc((0.7em + 0.1lvh) - 0.1lvw);
    width: 45lvw;
    margin-bottom: 0;
    height: 10lvh;
  }

  .text1-1 {
    font-size: calc((0.7em + 0.1lvh) - 0.1lvw);
    width: 45lvw;
    margin-bottom: 0;
    height: 8lvh;
  }

  .text1-3 {
    font-size: calc((0.7em + 0.1lvh) - 0.1lvw);
    width: 45lvw;
    height: 3lvh;
    margin-bottom: 0;
  }

  .text2 {
    font-size: calc((0.7em + 0.1lvh) - 0.1lvw);
    width: 45lvw;
  }
}

@media (max-width: 770px) {
  .humanBox {
    height: 25lvh;
  }

  .text1,
  .text1-1,
  .text1-3,
  .text2 {
    font-size: calc((0.6em + 0.1lvh) - 0.1lvw);
  }

  .text1 {
    height: 10lvh;
  }

  .human > .humanBox:nth-child(3) > .textBox {
    height: 20lvh;
  }

  .photo {
    padding-top: 4lvh;
  }

  .photo img {
    width: 12lvw;
    height: 8lvh;
  }

  .header {
    font-size: calc((0.4em + 0.1lvh) - 0.1lvw);
    margin-top: 14lvh;
    padding-top: 1lvh;
    height: 4lvh;
  }
}

@media (max-width: 720px) {
  .humanBox {
    height: 27lvh;
  }

  .text1,
  .text1-1,
  .text1-3,
  .text2 {
    font-size: calc((0.6em + 0.1lvh) - 0.1lvw);
    line-height: calc(0.4em + 1lvh);
    width: 100%;
  }

  .human > .humanBox:nth-child(3) > .textBox {
    height: 20lvh;
  }

  .photo {
    padding-top: 2lvh;
  }

  .photo img {
    width: 10lvw;
    height: 8lvh;
  }

  .header {
    font-size: calc((0.5em + 0.1lvh) - 0.1lvw);
    margin-top: 12lvh;
    padding-top: 1lvh;
    height: 4lvh;
  }

  .text1 {
    height: 11lvh;
  }

  .text1-1 {
    height: 9lvh;
  }

  .text1-3 {
    height: 4lvh;
  }
}

@media (max-width: 660px) {
  .humanBox {
    height: 100lvh;
  }

  .human > .humanBox:nth-child(3) > .textBox {
    height: 80lvh;
  }

  .text1,
  .text1-1,
  .text1-3,
  .text2 {
    font-size: calc((0.7em + 0.1lvh) - 0.1lvw);
  }

  .photo {
    padding-top: 4lvh;
  }

  .photo img {
    width: 10lvw;
    height: 16lvh;
  }

  .header {
    font-size: calc((0.5em + 0.1lvh) - 0.1lvw);
    margin-top: 23lvh;
    padding-top: 2lvh;
    height: 12lvh;
  }

  .text1 {
    height: 35lvh;
  }

  .text1-1 {
    height: 30lvh;
  }

  .text1-3 {
    height: 15lvh;
  }
}

@media (max-width: 540px) {
  .humanBox {
    height: 40lvh;
  }

  .text1,
  .text1-1,
  .text1-3,
  .text2 {
    font-size: calc((0.6em + 0.1lvh) - 0.1lvw);
  }

  .photo {
    padding-top: 4lvh;
    transform: translateX(10lvw);
  }

  .photo img {
    width: 14lvw;
    height: 9lvh;
  }

  .human > .humanBox:nth-child(3) > .textBox {
    height: 35lvh;
  }

  .header {
    font-size: calc((0.5em + 0.1lvh) - 0.1lvw);
    margin-top: 16lvh;
    padding-top: 2lvh;
    height: 6lvh;
    width: 70lvw;
  }

  .text1 {
    height: 14lvh;
  }

  .text1-1 {
    height: 10lvh;
  }

  .text1-3 {
    height: 6lvh;
  }
}

@media (max-width: 420px) {
  .humanBox {
    height: 37lvh;
  }

  .text1,
  .text1-1,
  .text1-3,
  .text2 {
    font-size: calc((0.4em + 0.1lvh) - 0.1lvw);
  }

  .photo {
    padding-top: 4lvh;
  }

  .photo img {
    width: 14lvw;
    height: 9lvh;
  }

  .human > .humanBox:nth-child(3) > .textBox {
    height: 30lvh;
  }

  .header {
    font-size: calc((0.35em + 0.1lvh) - 0.1lvw);
    margin-top: 14lvh;
    padding-top: 2lvh;
    height: 6lvh;
    width: 30lvw;
  }
}

@media (max-width: 380px) {
  .humanBox {
    height: 45lvh;
  }

  .text1,
  .text1-1,
  .text1-3,
  .text2 {
    font-size: calc((0.4em + 0.1lvh) - 0.1lvw);
  }

  .photo {
    padding-top: 4lvh;
  }

  .photo img {
    width: 14lvw;
    height: 9lvh;
  }

  .human > .humanBox:nth-child(3) > .textBox {
    height: 35lvh;
  }

  .header {
    font-size: calc((0.35em + 0.1lvh) - 0.1lvw);
    margin-top: 16lvh;
    padding-top: 2lvh;
    height: 6lvh;
    width: 70lvw;
  }
}

@media (max-width: 360px) {
  .humanBox {
    height: 42lvh;
  }

  .text1,
  .text1-1,
  .text1-3,
  .text2 {
    font-size: calc((0.5em + 0.1lvh) - 0.1lvw);
  }

  .photo {
    padding-top: 4lvh;
  }

  .photo img {
    width: 12lvw;
    height: 7lvh;
  }

  .header {
    font-size: calc((0.4em + 0.1lvh) - 0.1lvw);
    margin-top: 12lvh;
    padding-top: 1lvh;
    height: 4lvh;
    width: 30lvw;
  }
}

@media (max-width: 280px) {
  .humanBox {
    height: 45lvh;
  }

  .text1,
  .text1-1,
  .text1-3,
  .text2 {
    font-size: calc((0.4em + 0.1lvh) - 0.1lvw);
  }

  .photo {
    padding-top: 4lvh;
  }

  .photo img {
    width: 16lvw;
    height: 8lvh;
  }

  .header {
    font-size: calc((0.35em + 0.1lvh) - 0.1lvw);
    margin-top: 14lvh;
    padding-top: 1.2lvh;
    height: 6lvh;
  }
}
