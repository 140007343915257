.f-block {
  width: 100%;
  height: 12vh;
  background-color: var(--primary);
}

.a-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 5rem;
}

.f-text {
  color: var(--whiteColor);
  padding-top: 0.1rem;
}

.f-text h5 {
  color: var(--secondary);
  transform: translateY(0.7rem);
  font-size: calc(0.1rem + 0.8vw);
}

.f_address {
  display: flex;
  flex-direction: row;
  font-size: calc(0.1rem + 0.6vw);
  padding-top: 0.1rem;
}

.f_address p {
  font-size: calc(0.1rem + 0.5vw);
  transform: translateY(0.7rem) translateX(5rem);
}

.f_address i {
  transform: translateY(0.7rem) translateX(4.7rem) scale(1, 1);
}

.f-info {
  transform: translateY(0.8rem);
  padding-right: calc(1rem + 2vw);
}

.f-info1 {
  display: flex;
  flex-direction: row;
  padding: 0 2rem;
  font-size: calc(0.1rem + 0.5vw);
}

.f-info1 i {
  transform: translateX(-0.5rem) translateY(0.05rem) scale(1.1, 1.1);
}

.f-info2 {
  padding-left: calc(1rem + 13vw);
}

.f-info3 {
  transform: translateY(-0.78em);
  padding-left: calc(1rem + 27vw);
}

.f-logo p {
  color: var(--secondary);
  font-style: italic;
  font-size: calc(0.1rem + 0.5vw);
  transform: translateY(-0.2rem);
}

.f-logo {
  transform: translateY(-0.2rem);
}

.f-logo img {
  width: 14vw;
  height: 6vh;
  transform: translateY(0.2rem);
}

.f-copy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: calc(0.1rem + 0.45vw);
  color: var(--secondary);
  padding: 0 8.5rem;
  transform: translateY(-1.5em);
}

.f-copy span {
  color: var(--whiteColor);
  font-size: calc(0.1rem + 0.6vw);
  padding-left: 0.5rem;
}

@media (max-width: 1300px) {
  .f-info2 {
    transform: translateX(-1em);
  }

  .f-info3 {
    transform: translateX(-2em) translateY(-0.8em);
  }
}

@media (max-width: 1120px) {
  .f-info2 {
    transform: translateX(-1em) translateY(0.2em);
  }

  .f-info3 {
    transform: translateX(-2em) translateY(-0.5em);
  }

  .f-copy {
    transform: translateY(-1em);
  }
}

@media (max-width: 1010px) {
  .a-block {
    padding: 0 5rem;
  }

  .f_address {
    padding-top: 0.5rem;
    padding-bottom: .5rem;
  }

  .f-copy {
    padding: .5rem 8.1rem;
  }

  .f-logo img {
    padding-top: 1vh;
    height: 3.5vh;
  }
}

@media (max-width: 880px) {
  .f-block {
    height: 9vh;
  }

  .a-block {
    padding: 0 1rem;
  }

  .f_address {
    transform: translateX(-1rem);
  }

  .f_address i {
    transform: translateY(0.7rem) translateX(4.7rem);
  }

  .f-info2 {
    transform: translateY(0.2em) translateX(-0.7em);
  }

  .f-info3 {
    transform: translateY(-0.5em) translateX(-1em);
  }

  .f-copy {
    padding: 0 4rem;
  }

  .f-logo img {
    width: 14vw;
    height: 4vh;
  }
}

@media (max-width: 770px) {
  .f-info3 {
    transform: translateY(-0.5em) translateX(-0.9em);
  }

  .f-copy {
    transform: translateY(-0.2em);
  }
}

@media (max-width: 750px) {
  .f_address {
    transform: translateX(-1rem);
  }

  .f_address p {
    transform: translateY(0.3rem) translateX(5rem);
  }

  .f_address i {
    transform: translateY(0.4rem) translateX(4.7rem);
  }

  .f-copy {
    padding: 0 3rem;
  }

  .f-info3 {
    transform: translateY(-0.4em) translateX(-0.9em);
  }

  .f-logo {
    padding-top: 0.5rem;
  }

  .f-logo img {
    width: 14vw;
    height: 5vh;
  }
}

@media (max-width: 680px) {
  .f-block {
    height: inherit;
  }
  .f-info2 {
    transform: translateY(-0.05em) translateX(-0.3em);
  }

  .f-info3 {
    transform: translateY(-0.45em) translateX(-0.2em);
  }

  .f-info {
    transform: translateY(0.4em);
  }

  .f-copy {
    transform: translateY(-1.5em);
  }
}

@media (max-width: 640px) {
  .f-block {
    height: 10vh;
  }

  .f_address {
    transform: translateX(-1rem);
  }

  .f_address i {
    transform: translateY(0.4rem) translateX(4.7rem);
  }

  .f-copy {
    padding: 0 3rem;
  }

  .f-info1:nth-child(2) i {
    transform: translateX(-2rem) translateY(0.1rem) scale(1.1, 1.1);
  }

  .f-info1:nth-child(3) i {
    transform: translateX(-3.7rem) translateY(0.1rem) scale(1.1, 1.1);
  }

  .f-info1:nth-child(2) p {
    transform: translateX(-1.4rem);
  }

  .f-info1:nth-child(3) p {
    transform: translateX(-3.2rem);
  }

  .f-logo img {
    width: 14vw;
    height: 4vh;
  }
}

@media (max-width: 600px) {
  .f-info2 {
    transform: translateY(0.3em) translateX(0.2em);
  }

  .f-info3 {
    transform: translateY(-0.2em) translateX(1em);
  }
}

@media (max-width: 520px) {
  .f-block {
    height: 8vh;
  }

  .f_address {
    transform: translateY(-0.2rem) translateX(-2rem);
  }

  .f-info3 {
    transform: translateY(-0.1em) translateX(1.2em);
  }

  .f-logo img {
    width: 10vw;
    height: 3vh;
  }
}

@media (max-width: 505px) {
  .f-text {
    width: 100%;
  }

  .f-logo {
    width: 25%;
  }
}

@media (max-width: 480px) {
  .f-text h5 {
    transform: translateY(0.6em);
  }

  .f-logo img {
    width: 12vw;
    height: 2vh;
  }
}

@media (max-width: 420px) {
  .f-info3 {
    transform: translateY(-0.1em) translateX(1.4em);
  }

  .f-text h5 {
    transform: translateY(1em);
  }

  .f_address {
    padding-bottom: 0;
  }

  .f-copy {
    padding: 0 2rem;
  }

  .f-logo img {
    padding-top: 0;
    width: 14vw;
    height: 3vh;
  }
}
