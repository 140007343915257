.contactContainer {
  padding-top: 16vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 750px;
  background-color: var(--secondary);
}

.infoContact {
  margin: 0 3vw;
  width: 50%;
  height: 100%;
  color: var(--whiteColor);
}

.infoContact h1 {
  font-size: calc(0.6rem + 1vw);
  text-align: start;
  padding: 0 0 1vw 0;
  color: var(--whiteColor);
}

.infoContact h2 {
  font-size: calc(0.45rem + 1vw);
  color: var(--headerColor);
  text-align: start;
}

.address {
  width: 100%;
  height: 20%;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 4vh 1vw 1vh 0;
}

.address-icon {
  width: 30%;
  height: 100%;
}

.address-icon i {
  padding: 1.5vh 0 0 2vw;
  font-size: calc(3rem + 1vw);
}

.address-Text {
  width: 79%;
  height: 100%;
}

.address-Text h3 {
  font-size: calc(0.5rem + 0.9vw);
  text-align: start;
}

.address-Text p {
  padding-top: 1vh;
  font-size: calc(0.4rem + 0.5vw);
  text-align: start;
}

.contact {
  margin: 0 3vw;
  width: 50%;
  height: 100%;
}

.contactForm {
  width: 100%;
  height: 100%;
  padding: 1vh 2vw 2vh 2vw;
  display: flex;
  flex-direction: column;
  color: var(--whiteColor);
}

.formLine {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
}

.formAddress {
  width: 100%;
  height: 15%;
}

.formText {
  width: 100%;
  height: 35%;
}

.formButton {
  width: 100%;
  height: 10%;
}

.formRow {
  width: 50%;
  height: 100%;
}

.labelRow1 {
  width: 100%;
  height: 25%;
}

.labelRow1 label {
  color: var(--whiteColor);
  font-size: calc(0.4rem + 0.5vw);
}

.labelRow1 span {
  color: var(--redColor);
  padding-left: 0.5vw;
  font-size: calc(0.4rem + 0.5vw);
}

.labelText {
  width: 100%;
  height: 10%;
}

.labelText label {
  color: var(--whiteColor);
  font-size: calc(0.4rem + 0.5vw);
}

.labelText span {
  color: var(--redColor);
  padding-left: 0.5vw;
  font-size: calc(0.4rem + 0.5vw);
}

.inputRow1 {
  width: 100%;
  height: 90%;
}

.contactInput {
  margin-top: 1vh;
  width: 80%;
  height: 65%;
  border-radius: 1em;
  padding-left: 0.5vw;
  caret-color: var(--primary);
  border: none;
  outline: none;
}

.inputText {
  width: 100%;
  height: 100%;
}

.textInput {
  margin-top: 1vh;
  width: 90%;
  height: 65%;
  border-radius: 1em;
  padding-left: 0.5vw;
  caret-color: var(--primary);
  border: none;
  outline: none;
}

.cursor-position {
  padding-top: 1vh;
}

button {
  background-color: var(--primary);
  width: 30%;
  height: 80%;
  border-radius: 1em;
  cursor: pointer;
  color: var(--whiteColor);
  font-weight: 800;
  border: none;
  outline: none;
  font-size: calc(0.5rem + 0.5vw);
}

input::placeholder {
  font-size: calc(0.5rem + 0.5vw);
  text-align: center;
  color: var(--primary);
  font-weight: 600;
}

textarea::placeholder {
  font-size: calc(0.5rem + 0.5vw);
  padding-top: calc(4rem + 1vh);
  text-align: center;
  color: var(--primary);
  font-weight: 600;
}

@media (max-width: 950px) {
  .contactContainer {
    display: flex;
    flex-direction: column;
    height: 1100px;
  }

  .infoContact {
    width: 97%;
    height: 50%;
  }

  .address-icon i {
    padding-top: 0.5vh;
  }

  .address-Text {
    padding-top: 1vh;
  }

  .contact {
    width: 97%;
    height: 60%;
  }

  .contactForm {
    width: 80%;
    margin-left: 8vw;
  }
}

@media (max-width: 710px) {
  .infoContact h1 {
    font-size: calc(0.55rem + 0.5vw);
  }

  .infoContact h2 {
    font-size: calc(0.45rem + 0.5vw);
  }

  .address-icon i {
    font-size: calc(2rem + 1vw);
  }

  .address-Text {
    padding-left: 1vw;
  }

  .address-Text h3 {
    font-size: calc(0.6rem + 0.5vw);
  }

  .address-Text p {
    font-size: calc(0.4rem + 0.5vw);
  }

  .labelRow1 label {
    font-size: calc(0.2rem + 0.5vw);
  }

  .labelRow1 span {
    font-size: calc(0.2rem + 0.5vw);
  }

  .labelText label {
    font-size: calc(0.2rem + 0.5vw);
  }

  .labelText span {
    font-size: calc(0.2rem + 0.5vw);
  }

  input::placeholder {
    font-size: calc(0.3rem + 0.5vw);
  }

  textarea::placeholder {
    font-size: calc(0.3rem + 0.5vw);
  }

  button {
    font-size: calc(0.3rem + 0.5vw);
  }
}

@media (min-width: 1400px) {
  .contactContainer {
    height: 900px;
  }
}
