@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600;900&display=swap");
:root {
  --primary: #BB8732;
  --secondary: #013F41;
  --headerColor: darkgray;
  --whiteColor: #fff;
  --redColor: red;
  --brownColor: #222;
  --headerFontSize: 1.48rem;
  --pageFontSize: 1rem;
  --sliderFontSize: 1.5rem;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 14px;
}

.App {
  text-align: center;
}